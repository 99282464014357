import './App.css';
import React from 'react';
import Global from './responsive_util/global';
import Home from './responsive_util/home';


function App() {
  return (
    <>
      <Global />
      <Home />
    </>
  );
}

export default App;
