import React from "react";

import { Container, Box,RightBox, BoxTitle, BoxText, SilvyImage } from "./homestyles";
import { ReactComponent as SilvySHeader } from '../assets/images/silvy_s_header.svg';
import { ReactComponent as SilvySHeader2 } from '../assets/images/silvy_s_header_2.svg';
import wordmark from '../assets/images/silvy_coral_wordmark.svg';
import silvyCharacter from '../assets/images/silvy_pass_shadow_low.png';
import { ReactComponent as SilvyIcon } from '../assets/images/silvy_white_icon.svg';

import '../App.css';
import JoinRequest from '../network/server';
import {Col,Row} from 'react-bootstrap';


import Fade from 'react-reveal/Fade';

import Pulse from 'react-reveal/Pulse';

export default function Home({ boxData }) {
  return (

  <div>
    <Col className="col">
    {/* <div className="outer-div" >
        <SilvySHeader className="App-silvysheader" ></SilvySHeader>
        <SilvySHeader2 className="App-silvysheader2" ></SilvySHeader2>
        
    </div> */}
    <div className="App-bg-s">
      {/* <SilvySHeader className="App-silvysheader" ></SilvySHeader> */}
      {/* <SilvySHeader2 className="App-silvysheader2" ></SilvySHeader2> */}
      
    </div>
      <Container bgColor={'#fff'}> 
        <Box key={11} bgColor={'#000'} >
        <img src={wordmark} className="App-silvy-wordmark" alt="Silvy" />
          <div className="formFields">            
            <p className="silvyHeader">Let's start<br/>a journey together</p>
            <p className="earlyaccess">Early Access</p>
            <p className="desc">Sign up for our waitlist, and be one of the first ones to try out Silvy!</p>
              <JoinRequest></JoinRequest>    
          </div>

          </Box>
            <RightBox key={2} bgColor={'#ffede4'} mobileBgColor={'#fff'} >
              <SilvyIcon className="silvyIconBg"></SilvyIcon>
              <SilvyIcon className="silvyIconBg2"></SilvyIcon>
              
              <SilvyImage src={silvyCharacter}></SilvyImage>
              
              {/* <img  src={silvyCharacter} className="App-silvy-character" alt="Silvy-Character" /> */}
            </RightBox>
      </Container>
    </Col>
  </div>

  
  );
}
