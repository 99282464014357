import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  max-width: 100%;
  height: 100%;
  background-color: ${props => props.bgColor};
  @media (max-width: 940px) {
    height: 200%;
    flex-wrap: wrap;  
    font-family: 'dmsans-regular';

  }
`;

export const Box = styled.div`
  width: 100%;
  min-width:50%;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  @media (max-width: 940px) {
    width: 100%;
  }
`;


export const RightBox = styled.div`
  display: flex;
  width: 100%;
  min-width:50%;
  flex-direction: column;
  background-color: ${props => props.bgColor};
  flex-wrap: nowrap;
  position: relative;
  overflow:hidden;
  height: 100vh;
  @media (max-width: 940px) {
    width: 100%;
    height: 100%;
    overflow:hidden;
    background-color: ${props => props.mobileBgColor};
  }
`;

export const SilvyImage = styled.img`
    src=${props => props.src};
    height:75%;
    object-fit: contain;
    margin-top: 15%;
    z-index: 0;
    @media (max-width: 940px) {
        height: 100%;
        width: 100%;
        margin-right:0px;
        margin-top: 0px;
        background-image: linear-gradient(rgba(0,0,0,0), #ffede4);
    }
`;

